<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { tableData } from "./data-advancedtable";

import {
  required,
} from "vuelidate/lib/validators";

/**
 * Afiliados component
 */
export default {
  page: {
    title: "Gerenciar Afiliados",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      tableData: tableData,
      title: "Gerenciar Afiliados",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Gerenciar Afiliados",
          active: true,
        },
      ],  
      showModal: false,
      tableHighlighted: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fieldAliases: {
        id: "#",
        name: "Nome",
        cod: "Código",
        percentage: "Percentual",
        status: "Status",
        qtSales: "Quantidade de Vendas",
        totalSales: "Total de Vendas",
        actions: "Ações",
      }, 
      form: {
        name: "",
        cod: "",
        percentage: "",
      },   
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    }; 
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    fields() {
      return [
        { key: "id", sortable: true, label: this.fieldAliases.id, thStyle: { width: "8%" } },
        { key: "name", sortable: true, label: this.fieldAliases.name, thStyle: { width: "30%" } },
        { key: "cod", sortable: true, label: this.fieldAliases.cod },
        { key: "percentage", sortable: true, label: this.fieldAliases.percentage },
        { key: "status", sortable: true, label: this.fieldAliases.status },
        { key: "qt_sales", sortable: true, label: this.fieldAliases.qtSales },
        { key: "total_sales", sortable: true, label: this.fieldAliases.totalSales },
        { key: "actions", sortable: true, label: this.fieldAliases.actions, thStyle: { width: "110px" } }
      ];
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  validations: {
    form: {
      name: { required },
      cod: { required },
      percentage: { required },
    },
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    handleNewCad(value) {
      this.showModal = value;
    },

    deleteItem() {
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :addText="'Adicionar Novo Afiliado'" @newCad="handleNewCad" />
    <div class="row">
      <div class="col-12">
        <div class="card">        
          <div class="card-body">
            <h4 class="card-title">Afiliados</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar
                    <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage" size="sm" :options="pageOptions"></b-form-select> Registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Pesquisar: 
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Digite algo..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(status)="obj">
                  <div class="actions-cell">    
                    <span class="badge ms-1" :class="obj.item.status == 'A' ? 'bg-success' : 'bg-warning'">
                      <h6 class="m-0">{{obj.item.status == "A" ? "Ativo" : "Inativo"}}</h6>
                    </span>                   
                  </div>
                </template>
                <!-- Coluna "actions" personalizada -->
                <template #cell(actions)="category">
                  <div class="actions-cell">                    
                    <b-button class="w-100" pill @click="deleteItem(category.item)" variant="outline-primary">
                      <i class="mdi mdi-dots-vertical"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 

    <b-modal v-model="showModal" id="modal-dialog" title="Cadastrar Afiliado" title-class="font-18" hide-footer>
      <form class="needs-validation" @submit.prevent="formSubmit">       
        <div class="row mb-4">
          <div class="col">
            <label class="form-label" for="title">Nome <span class="required">*</span></label>
            <input
              id="name"
              v-model="form.name"
              type="text"
              class="form-control"
              placeholder="Digite o nome"
              :class="{
                'is-invalid': submitted && $v.form.name.$error,
              }"
            />
            <div
              v-if="submitted && $v.form.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.form.name.required"
                >Por favor, digite o nome.</span
              >
            </div>
          </div>
        </div>    
        <div class="row mb-4">
          <div class="col">
            <label class="form-label" for="title">Código <span class="required">*</span></label>
            <input
              id="cod"
              v-model="form.cod"
              type="text"
              class="form-control"
              placeholder="Digite o código"
              :class="{
                'is-invalid': submitted && $v.form.cod.$error,
              }"
            />
            <div
              v-if="submitted && $v.form.cod.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.form.cod.required"
                >Por favor, digite o código.</span
              >
            </div>
          </div>
        </div>  
        <div class="row mb-4">
          <div class="col">
            <label class="form-label" for="title">Porcentagem de Comissão <span class="required">*</span></label>
            <input
              id="percentage"
              v-model="form.percentage"
              type="number"
              class="form-control"
              placeholder="Digite o percentual"
              :class="{
                'is-invalid': submitted && $v.form.percentage.$error,
              }"
            />
            <div
              v-if="submitted && $v.form.percentage.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.form.percentage.required"
                >Por favor, digite o percentual.</span
              >
            </div>
          </div>
        </div>        
        <div class="mt-2 text-end">
            <b-button variant="light" @click="handleNewCad(false)">Fechar</b-button>
            <b-button type="submit" variant="info" class="ms-1"
              >Cadastrar</b-button
            >
          </div>
      </form>    
    </b-modal>
    <!-- end row -->
  </Layout>
</template>

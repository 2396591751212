const tableData = [
  {
    id: 1,
    name: 'Recruiting Manstatusr',
    cod: '1',
    percentage: '10%',
    status: 'A',
    qt_sales: 12,
    total_sales: "R$200",
  }, {
    id: 2,
    name: 'Recruiting Manstatusr',
    cod: '1',
    percentage: '10%',
    status: 'I',
    qt_sales: 12,
    total_sales: "R$200",
  }, {
    id: 3,
    name: 'Recruiting Manstatusr',
    cod: '1',
    percentage: '10%',
    status: 'I',
    qt_sales: 12,
    total_sales: "R$200",
  }, 
]

export { tableData };
